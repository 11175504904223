export default {
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register"])},
  "log-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "date-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date from"])},
  "date-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date to"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
  "location-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location code"])},
  "number-of-eggs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of eggs"])},
  "ovitrap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ovitrap"])},
  "ovitrap-stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ovitrap stations"])},
  "eggs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eggs"])},
  "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["station"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["process"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
  "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["processed"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
  "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleted"])},
  "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metadata"])},
  "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["threshold"])},
  "file-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file input"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
  "mean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mean"])},
  "10th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10th"])},
  "90th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90th"])},
  "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentile"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
  "plot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plot"])},
  "login-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login form"])},
  "register-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])},
  "time-scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time scale"])},
  "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weekly"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly"])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jan"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feb"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mar"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apr"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jun"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jul"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aug"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sep"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oct"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nov"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dec"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])},
  "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["database"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reports"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["help"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])},
  "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log out"])},
  "home-welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "home-app-protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application is protected, please"])},
  "home-get-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload stick pictures to get started."])},
  "upload-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For each stick, you can upload either the front picture or both the front and the back picture. Please make sure to enter the location code when uploading the stick pictures."])},
  "upload-1-2-pics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are eggs on the stick, please upload a front picture and (optionally) a back picture:"])},
  "upload-enter-location-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the location code:"])},
  "upload-otherwise-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otherwise, directly enter the number of eggs:"])},
  "upload-location-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location code"])},
  "upload-add-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add record"])},
  "database-no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we don't have any data to show."])},
  "database-total-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total count"])},
  "database-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "editor-sure-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the record"])},
  "editor-record-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record deleted"])},
  "editor-record-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record saved"])},
  "editor-front-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front picture"])},
  "editor-back-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back picture"])},
  "editor-location-code-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A location code is required"])},
  "editor-happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am happy with the results"])},
  "editor-new-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the new location code for the record"])},
  "reports-current-trend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current trend"])},
  "reports-positive-ovitrap-ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positive ovitrap ratio"])},
  "reports-average-positive-ovitrap-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average positive ovitrap count"])},
  "reports-difference-since-previous-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["difference since previous week"])},
  "reports-eggs-per-ovitrap-station-at-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eggs per ovitrap station at week"])},
  "reports-evolution-from-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evolution from week"])},
  "reports-time-scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time scale"])},
  "reports-insights-for-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insights for week"])},
  "contact-git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any question, request, feedback or inquiry, feel free to get in touch with us through GitLab issues:"])},
  "contact-git-front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-end repository (user interface, plots, visualization)"])},
  "contact-git-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-end repository (counting algorithm, database management)"])},
  "contact-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or directly contact us at:"])},
  "settings-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
  "settings-ovitrap-stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ovitrap stations"])},
  "settings-upload-stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a valid GeoJSON file featuring the ovitrap stations. The root object should be a FeatureCollection, whose features attribute contains the list of Feature objects with their location code in properties.id and GPS coordinates in geometry.coordinates."])},
  "settings-upload-stations-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that uploading the ovitrap stations will effectively delete the current ones."])}
}