export default {
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sí"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regístrese"])},
  "log-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingrese con sus credenciales"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre de usuario"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clave"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "date-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha desde"])},
  "date-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha hasta"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ubicación"])},
  "location-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de ubicación"])},
  "number-of-eggs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de huevos"])},
  "ovitrap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ovitrampa"])},
  "ovitrap-stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estaciones de ovitrampas"])},
  "eggs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huevos"])},
  "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estación"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procesar"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar"])},
  "processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procesado"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar"])},
  "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminado"])},
  "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metadatos"])},
  "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["umbral"])},
  "file-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar archivo"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descargar"])},
  "mean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promedio"])},
  "10th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
  "90th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90"])},
  "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentil"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datos"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar"])},
  "plot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gráfico"])},
  "login-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "register-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de registro"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semana"])},
  "time-scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala temporal"])},
  "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semanal"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensual"])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ene"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feb"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mar"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abr"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jun"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jul"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ago"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sep"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oct"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nov"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dic"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subir fotos"])},
  "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["base de datos"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportes"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayuda"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacto"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajustes"])},
  "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salir"])},
  "home-welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenidos"])},
  "home-app-protected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación está protegida, por favor"])},
  "home-get-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suba alguna foto de paletas de ovitrampas para empezar."])},
  "upload-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para cada paleta de ovitrampa, usted puede subir opcionalmente solo la foto del frente o los dos lados. Por favor asegúrese de ingresar el código de ubicación de la ovitrampa al cargar las fotos de las paletas."])},
  "upload-1-2-pics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor cargue 1 o 2 fotos:"])},
  "upload-enter-location-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de ubicación de la ovitrampa:"])},
  "upload-otherwise-manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O directamente ingrese el número de huevos"])},
  "upload-location-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de ubicación"])},
  "upload-add-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar registro"])},
  "database-no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para mostrar."])},
  "database-total-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteo total"])},
  "database-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "editor-sure-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está seguro que desea eliminar el registro"])},
  "editor-record-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro eliminado"])},
  "editor-record-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro guardado"])},
  "editor-front-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de frente"])},
  "editor-back-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de atrás"])},
  "editor-location-code-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere un código de ubicación"])},
  "editor-happy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy contento con los resultados"])},
  "editor-new-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el nuevo código de ubicación para el registro"])},
  "reports-current-trend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tendencia actual"])},
  "reports-positive-ovitrap-ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proporción de ovitrampas positivas"])},
  "reports-average-positive-ovitrap-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conteo promedio de las ovitrampas positivas"])},
  "reports-difference-since-previous-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diferencia con la semana anterior"])},
  "reports-eggs-per-ovitrap-station-at-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de huevos por ovitrampa en la semana"])},
  "reports-evolution-from-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evolución desde la semana"])},
  "reports-time-scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["escala temporal"])},
  "reports-insights-for-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["información para la semana"])},
  "contact-git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por cualquier pregunta, solicitud, comentario o consulta, no dude en ponerse en contacto con nosotros a través de GitLab:"])},
  "contact-git-front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repositorio front-end (interfaz de usuario, gráficos, visualización)"])},
  "contact-git-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repositorio back-end (algoritmo de conteo de huevos, gestión de la base de datos)"])},
  "contact-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O directamente póngase en contacto con nosotros por correo electrónico:"])},
  "settings-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
  "settings-ovitrap-stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ubicación de las ovitrampas"])},
  "settings-upload-stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor cargue un archivo GeoJSON válido con la ubicación de las ovitrampas. El objeto raíz del GeoJSON debería ser una FeatureCollection, cuyo atributo features contenga la lista de Feature objects con su código de ubicación/nombre de la estación de muestreo en properties.id y coordenadas de GPS en geometry.coordinates."])},
  "settings-upload-stations-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención! El nuevo archivo con la ubicación de las ovitrampas reemplazará el existente."])}
}