<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">{{ $t('home-welcome') }}</h1>


        <p class="subheading font-weight-regular">
         {{ $t('home-app-protected') }} 
         <a href="/login">{{ $t('log-in') }}</a> 
         {{ $t('or') }} 
         <a href="/register">{{ $t('register') }}</a>.
        </p>
      </v-col>

     
    </v-row>
  </v-container>
</template>

<script>


export default {
  name: 'IntroductionComponent',

    data() {
    return {
    }
  }
}
</script>
